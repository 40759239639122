@import url("https://fonts.googleapis.com/css2?family=Darker+Grotesque:wght@300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  font-family: "Darker Grotesque", sans-serif;
  font-weight: 400;
  line-height: 1.7;
  color: #777;
}

/* HELPER CLASSES */
.component {
  padding: 9.6rem 0;
  margin: 0 auto;
  max-width: 120rem;
}
.components-header-h2 {
  font-size: 6.2rem;
  margin-bottom: 9.6rem;
}

*:focus {
  outline: none;
  box-shadow: 0 0 0 0.2rem #bd985f;
}

/* GENERAL QUERIES */

/*****************************/
/* BELOW 1344px (Smaller desktops) */
/*****************************/

@media (max-width: 84em) {
  /* NONE */
  .component-header-h2 {
    font-size: 6.2rem;
    margin-bottom: 9.6rem;
  }
  .component {
    padding: 9.6rem 2.4rem;
  }
}

/*****************************/
/* BELOW 1200px (Landscape Tablets) */
/*****************************/

@media (max-width: 75em) {
  html {
    /*  9px / 16px = 56.25% */
    font-size: 56.25%;
  }
  .components-header-h2 {
    /* font-size: 4.2rem; */
    /* line-height: 1; */
    margin-bottom: 7.2rem;
  }
}

/*****************************/
/* BELOW 944px (Tablets) */
/*****************************/

@media (max-width: 59em) {
  html {
    /*  8px / 16px = 50% */
    font-size: 50%;
  }
  .component {
    padding: 9.6rem 1.2rem;
  }

  .components-header-h2 {
    font-size: 5.2rem;
  }
}

/*****************************/
/* BELOW 704px (Smaller Tablets) */
/*****************************/

@media (max-width: 44em) {
  /* NONE */
  .component {
    padding: 9.6rem 1.2rem 4.8rem 1.2rem;
    margin: 0 auto;
    max-width: 120rem;
  }
}

/*****************************/
/* BELOW 544px (Phones) */
/*****************************/

@media (max-width: 34em) {
  .component {
    padding: 9.6rem 2.4rem 4.8rem 2.4rem;
    margin: 0 auto;
  }
  .components-header-h2 {
    font-size: 4.2rem;
    line-height: 1;
    margin-bottom: 7.2rem;
  }
}

/* To fix flexbox gap issues with old Safari Browsers */
.no-flexbox-gap .main-nav-list li:not(:last-child) {
  margin-right: 4.8rem;
}

.no-flexbox-gap .list-item:not(:last-child) {
  margin-bottom: 1.6rem;
}

.no-flexbox-gap .list-icon:not(:last-child) {
  margin-right: 1.6rem;
}

.no-flexbox-gap .delivered-faces {
  margin-right: 1.6rem;
}

.no-flexbox-gap .meal-attribute:not(:last-child) {
  margin-bottom: 2rem;
}

.no-flexbox-gap .meal-icon {
  margin-right: 1.6rem;
}

.no-flexbox-gap .footer-row div:not(:last-child) {
  margin-right: 6.4rem;
}

.no-flexbox-gap .social-links li:not(:last-child) {
  margin-right: 2.4rem;
}

.no-flexbox-gap .footer-nav li:not(:last-child) {
  margin-bottom: 2.4rem;
}

@media (max-width: 75em) {
  .no-flexbox-gap .main-nav-list li:not(:last-child) {
    margin-right: 3.2rem;
  }
}

@media (max-width: 59em) {
  .no-flexbox-gap .main-nav-list li:not(:last-child) {
    margin-right: 0;
    margin-bottom: 4.8rem;
  }
}
