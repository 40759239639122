.about {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4.8rem;
}

.about-img-div {
  height: 100%;
  background-image: url(../../public/samuel.webp);
  background-size: cover;
  background-position: top;
  clip-path: polygon(15% 0%, 100% 0%, 85% 100%, 0% 100%);
}

.about-content {
  padding: 4.8rem 4.8rem;

  display: flex;
  flex-direction: column;
}

.about-content h2 {
  color: #333;
  margin-bottom: 4.8rem !important;
}

.about-content p {
  font-size: 2.4rem;
  font-weight: 500;
  color: #333;
}

.about-bold {
  font-weight: 800;
}

/* GENERAL QUERIES */

/*****************************/
/* BELOW 1344px (Smaller desktops) */
/*****************************/

@media (max-width: 84em) {
  /* NONE */
}

/*****************************/
/* BELOW 1200px (Landscape Tablets) */
/*****************************/

@media (max-width: 75em) {
}

/*****************************/
/* BELOW 944px (Tablets) */
/*****************************/

@media (max-width: 59em) {
  .about {
    gap: 2.4rem;
  }
  .about-content h2 {
    color: #333;
    margin-bottom: 3.6rem !important;
  }
}

/*****************************/
/* BELOW 704px (Smaller Tablets) */
/*****************************/

@media (max-width: 44em) {
  .about {
    grid-template-columns: 1fr;
    gap: 6.2rem;
    /* gap: 9.6rem; */
  }

  .about-content {
    padding: 0rem 4.8rem;

    grid-row: 1;

    display: flex;
    flex-direction: column;
  }

  .about-img-div {
    height: 50rem;

    clip-path: polygon(5% 0%, 100% 0%, 95% 100%, 0% 100%);
    margin: 0 4.8rem;
  }

  .about-content h2 {
    color: #333;
  }
}

/*****************************/
/* BELOW 544px (Phones) */
/*****************************/

@media (max-width: 34em) {
  .about {
    /* padding: 4.8rem 2.4rem; */
    margin: 0 auto;
    gap: 4.8rem;
  }
  .about-content h2 {
    color: #333;
    line-height: 1;
    margin-bottom: 4.8rem;
  }
  .about-content {
    padding: 0rem 2.4rem;
  }

  .about-img-div {
    height: 50rem;

    /* clip-path: polygon(5% 0%, 100% 0%, 95% 100%, 0% 100%); */
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    margin: 0 1.2rem;
  }
}
