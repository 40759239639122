.section-services {
  background-image: linear-gradient(
      to bottom,
      rgba(256, 256, 256, 0.75),
      rgba(68, 68, 68, 0.5)
    ),
    url(../../public/services-background.webp);
  background-size: cover;
  background-position: top;
}

.services-content {
  background-color: inherit;
}

.services-content h2 {
  text-align: center;
  color: #333;
}

.services-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2.4rem;

  padding: 0 4.8rem;
}

.services-grid-box {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  overflow: hidden;
  background-color: #ddd;
  border-top: 6px solid #bd985f;
  border-bottom: 6px solid #bd985f;

  transition: all 0.3s;
}

.services-grid-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 1.2rem 3.2rem rgba(0, 0, 0, 0.1);
}

.services-grid-box-img {
  text-align: center;
  background-color: #222;
  padding: 3.2rem 3.2rem 2.4rem 3.2rem;
}

.services-grid-box-img img {
  width: 40%;
}

.services-grid-box-content {
  padding: 3.2rem;
}

.services-grid-box h3 {
  margin: 0;
  color: #fff;

  font-size: 3.6rem;
  text-align: center;
  letter-spacing: 1.4px;
  font-weight: 500;
  text-transform: capitalize;
}

.services-grid-box p {
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 1.1;
  color: #333;
}
.services-grid-box ul {
  list-style: none;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 2rem;
}

.services-grid-box li {
  font-size: 2.2rem;
  font-weight: 700;
  line-height: 1;
  display: flex;
  gap: 0.6rem;
  cursor: pointer;
  transition: all 0.3s;
}

.services-grid-box li:hover {
  color: #bd985f;
}

.services-item {
  display: block;
  margin: 0;
  line-height: 1;
}
.services-icon {
  color: #bd985f;
  margin-top: 0.4rem;
  align-self: flex-start;
  height: 1.5rem;
  width: 1.5rem;
}

.services-note {
  color: #333 !important;
  font-size: 1.8rem !important;
  font-weight: 400 !important;
}

/* GENERAL QUERIES */

/*****************************/
/* BELOW 1344px (Smaller desktops) */
/*****************************/

@media (max-width: 84em) {
  /* NONE */
  .services-grid {
    gap: 1.8rem;
    padding: 0 2.4rem;
  }
}

/*****************************/
/* BELOW 1200px (Landscape Tablets) */
/*****************************/

@media (max-width: 75em) {
  .services-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1.8rem;

    padding: 0 2.4rem;
  }
}

/*****************************/
/* BELOW 944px (Tablets) */
/*****************************/

@media (max-width: 59em) {
  .services-grid {
    padding: 0 1.8rem;

    gap: 0.8rem;
  }

  .services-grid-box-img img {
    width: 30%;
  }

  .services-grid-box h3 {
    font-size: 2.8rem;
  }

  .services-grid-box-content {
    padding: 2.4rem;
  }
}

/*****************************/
/* BELOW 704px (Smaller Tablets) */
/*****************************/

@media (max-width: 44em) {
  .services-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;

    padding: 0 1.2rem;
  }
}

/*****************************/
/* BELOW 544px (Phones) */
/*****************************/

@media (max-width: 34em) {
  .services-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2.4rem;

    padding: 0 0rem;
  }

  .services-grid-box-img img {
    width: 40%;
    padding: 0;
  }
  .services-grid-box h3 {
    font-size: 3.6rem;
  }

  .services-grid-box {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    overflow: hidden;
    background-color: #fff;
    border-top: 6px solid #bd985f;
    border-bottom: 6px solid #bd985f;
  }

  .services-grid-box-content {
    padding: 4.8rem;
  }
}
