.header {
  height: 8rem;
  width: 100%;
  padding: 0 2.4rem;

  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.45);
}

.header-dark {
  background-color: rgba(0, 0, 0, 0.9);
}

.nav-mobile-div {
  display: none;
}

.nav-logo-div {
  display: flex;
  align-items: center;
}
.nav-logo-large {
  width: 30rem;
}
.nav-logo-small {
  display: none;
  height: 6rem;
  width: 6rem;
}

.nav-items-div {
  width: 60%;

  display: flex;
  justify-content: center;
}

.header ul {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;

  list-style: none;
}

.header a:link,
.header a:visited {
  padding: 0rem 0.4rem;
  border-radius: 5px;
  font-size: 2.2rem;
  letter-spacing: 1.1px;

  color: #ccc;
  text-decoration: none;
  font-weight: 500;

  transition: all 0.3s;
}
.header a:hover,
.header a:active {
  color: #bd985f;
}

.nav-button {
  align-self: center;
  padding: 0rem 1.2rem !important;
  border-radius: 5px;
  border: 1px solid #bd985f;
  color: #bd985f !important;
  background-color: #333;
  cursor: pointer;
  transition: all 0.3s;

  display: inline-block;
}

.nav-button:hover,
.nav-button:active {
  background-color: rgba(189, 151, 95, 0.5) !important;
  color: #fff !important;
}

/* HEADER FORM */

.header-form-label {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.6rem;
  font-size: 2.2rem;
  line-height: 1;
  padding: 0rem 0.4rem;
  color: #ccc;
  text-decoration: none;
  font-weight: 500;
}
.header-form-icon {
  height: 2rem;
  width: 2rem;
  color: #ccc;
}
.header-form-select {
  border: 1px solid #bd985f;
  padding: 0.6rem 0.4rem;
  border-radius: 5px;
  background-color: #444;
  color: #ccc;
  font-size: 1.2rem;
}

/* GENERAL QUERIES */

/*****************************/
/* BELOW 1344px (Smaller desktops) */
/*****************************/

@media (max-width: 84em) {
  /* NONE */
}

/*****************************/
/* BELOW 1200px (Landscape Tablets) */
/*****************************/

@media (max-width: 75em) {
  .nav-items-div {
    width: 65%;
  }

  .header a:link,
  .header a:visited {
    font-size: 2rem;
    letter-spacing: 1px;
  }
}

/*****************************/
/* BELOW 944px (Tablets) */
/*****************************/

@media (max-width: 59em) {
  .header {
    padding: 0 0.6rem;
  }
  .nav-logo-large {
    width: 20rem;
  }
  .nav-items-div {
    width: 70%;
  }
  .header a:link,
  .header a:visited {
    font-size: 1.8rem;
    letter-spacing: 1px;
  }
}

/*****************************/
/* BELOW 704px (Smaller Tablets) */
/*****************************/

@media (max-width: 44em) {
  .header {
    padding: 0 1.2rem;
  }

  .nav-items-div {
    display: none;
  }

  .nav-logo-large {
    display: none;
  }
  .nav-logo-small {
    display: block;
  }

  .nav-mobile {
    display: flex;
    position: absolute;

    height: 100vh;
    width: 100%;
    top: 0rem;
    left: 0rem;
    transform: translateX(0%);
    transition: all 0.5s linear;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.9);

    padding: 2.4rem;

    /*  1 - Hide visually */
    opacity: 1;
    /*  2 - Make it unaccessible to mouse and keyboard */
    pointer-events: auto;
    /*  3 - Hide from screen readers */
    visibility: visible;
  }

  .nav-mobile--hidden {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    transform: translateX(100%);
  }

  .nav-mobile-div {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20;
  }

  .nav-mobile-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    /* align-self: center; */

    padding: 0.6rem 1.2rem;
    letter-spacing: 1.2px;
    font-size: 2.4rem;
    font-weight: 700;
    border-radius: 5px;
    /* border: 1px solid #bd985f;
    background-color: #fff;
    color: #bd985f !important; */
    border: 1px solid #bd985f;
    color: #bd985f;
    background-color: #333;
    cursor: pointer;

    transition: all 0.3s;
  }

  .nav-mobile-btn:hover,
  .nav-mobile-btn:active {
    background-color: #333;

    /* background-color: #000;
    color: #ccc; */
    /* background-color: #bd985f; */
    /* color: #fff; */
  }

  .nav-mobile-btn-icon {
    height: 2.8rem;
    width: 2.8rem;
  }

  .header ul {
    /* width: 100%; */
    display: flex;
    flex-direction: column;
    gap: 4.8rem;
    align-items: center;
    justify-content: center;

    list-style: none;
  }

  .header a:link,
  .header a:visited {
    font-size: 2.8rem;
  }

  .nav-button {
    font-size: 2.4rem;
  }
  .header-form-select {
    height: 3.6rem;
    font-size: 1.6rem;
  }
}

/*****************************/
/* BELOW 544px (Phones) */
/*****************************/

@media (max-width: 34em) {
  .nav-logo {
    width: 25rem;
  }
}
