.hero {
  height: 95vh;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.9),
    rgba(68, 68, 68, 0.9)
  );
}

.back-test {
  height: 100%;
}

.back-test {
  width: 100%;
  height: 100vh;
  transform: scale(1);
  animation: scaleAndFade 2.5s infinite ease-in-out;
  will-change: transform;
}

@keyframes scaleAndFade {
  0% {
    opacity: 0;
  }

  5% {
    opacity: 0;
  }

  30% {
    opacity: 1;
  }

  80% {
    opacity: 1;
  }

  100% {
    opacity: 1;
    transform: scale(1.3);
  }
}

.hero-logo-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  position: absolute;
  width: 100%;
  height: 50%;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);

  background-color: none;
}
.hero-logo {
  width: 85%;
  padding: 0 9.6rem;
  margin-bottom: 4.8rem;
  transition: opacity 1s ease-in-out;
  opacity: 1;
}

.fade-out {
  opacity: 0;
  pointer-events: none;
}

.fade-in {
  opacity: 1;
}

.hero-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;

  background: none;
  border: none;
  outline: none;

  color: rgba(255, 255, 255, 0.4); /* levemente transparente */
  cursor: pointer;
  padding: 0.2rem;
  transition: color 0.3s ease;
}

.hero-arrow:hover {
  color: rgba(255, 255, 255, 0.85);
}

.hero-arrow:focus {
  box-shadow: none;  
}

.hero-arrow.left {
  left: 20px;
}

.hero-arrow.right {
  right: 20px;
}


.test-font {
  color: #fff;
  text-transform: uppercase;
  font-size: 6rem;
}

/* GENERAL QUERIES */

/*****************************/
/* BELOW 1344px (Smaller desktops) */
/*****************************/

@media (max-width: 84em) {
  /* NONE */
}

/*****************************/
/* BELOW 944px (Tablets) */
/*****************************/

@media (max-width: 59em) {
  .hero-logo {
    width: 85%;
    padding: 0 0rem;
  }
}

/*****************************/
/* BELOW 704px (Smaller Tablets) */
/*****************************/

@media (max-width: 44em) {
  .hero-logo-div {
    top: 50%;
  }

  .hero-arrow {
    top: 55%;
    transform: translateY(-50%);
  }
}

/*****************************/
/* BELOW 544px (Phones) */
/*****************************/

@media (max-width: 34em) {
  .hero {
    height: 100vh;
  }
  .hero-logo {
    width: 95%;
  }
}