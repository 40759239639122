.groomsDay {
  /* background-color: #ccc; */
  background-image: linear-gradient(
      to bottom,
      rgba(256, 256, 256, 0.9),
      rgba(222, 221, 221, 0.5)
    ),
    url(../../public/services-background.webp);
  background-size: cover;
  background-position: top;
}
.groomsDay h2 {
  color: #333;
  margin-bottom: 4.8rem !important;
}
.groomsDay-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4.8rem;
}
.groomsDay-content {
  padding: 4.8rem 4.8rem;

  display: flex;
  flex-direction: column;
}

.groomsDay-grid p {
  font-size: 2.4rem;
  font-weight: 500;
  color: #333;
}

.groomsDay-img-box {
  height: 100%;
  background-image: url(../../public/groomsDay.webp);
  background-size: cover;
  background-position: top;
  clip-path: polygon(15% 0%, 100% 0%, 85% 100%, 0% 100%);
}

.groomsDay-bold {
  font-weight: 800;
}

/* GENERAL QUERIES */

/*****************************/
/* BELOW 1344px (Smaller desktops) */
/*****************************/

@media (max-width: 84em) {
  /* NONE */
}

/*****************************/
/* BELOW 1200px (Landscape Tablets) */
/*****************************/

@media (max-width: 75em) {
  .groomsDay-grid {
    grid-template-columns: 3fr 2fr;
    gap: 2.4rem;
  }

  .groomsDay-content {
    padding: 4.8rem 0rem;
  }
}

/*****************************/
/* BELOW 944px (Tablets) */
/*****************************/

@media (max-width: 59em) {
  .groomsDay-grid {
    grid-template-columns: 3fr 3fr;
    gap: 2.4rem;
  }

  .groomsDay-content {
    padding: 4.8rem 2.4rem;
  }
}

/*****************************/
/* BELOW 704px (Smaller Tablets) */
/*****************************/

@media (max-width: 44em) {
  .groomsDay-grid {
    grid-template-columns: 1fr;
    gap: 6.2rem;
  }

  .groomsDay-content {
    padding: 0rem 4.8rem;
  }

  .groomsDay-img-box {
    height: 50rem;
    clip-path: polygon(5% 0%, 100% 0%, 95% 100%, 0% 100%);
    margin: 0 4.8rem;
  }
}

/*****************************/
/* BELOW 544px (Phones) */
/*****************************/

@media (max-width: 34em) {
  .about {
    /* padding: 4.8rem 2.4rem; */
    margin: 0 auto;
    gap: 4.8rem;
  }
  .about-content h2 {
    color: #333;
    line-height: 1;
    margin-bottom: 4.8rem;
  }
  .about-content {
    padding: 0rem 2.4rem;
  }

  .groomsDay-content {
    padding: 0rem 2.4rem;
  }

  .groomsDay-img-box {
    height: 50rem;
    /* clip-path: polygon(5% 0%, 100% 0%, 95% 100%, 0% 100%); */
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);

    margin: 0 1.2rem;
  }
}
