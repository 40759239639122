.diamond-section {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient( to bottom, rgba(0, 0, 0, 0.95), rgba(68, 68, 68, 0.5) ), url(http://localhost:3000/static/media/cta-background.65f51c07746986c3aeaf.webp);
  padding: 8rem 2rem; 
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

.diamond-box h2 {
  text-align: left;
  color: #bd985f;
  font-size: 7rem; 
  margin-bottom: 4rem; 
  font-weight: 700;
  letter-spacing: 2px; 
  text-transform: uppercase;
}

.diamond-card {
  max-width: 1100px; 
  background-color: rgba(0, 0, 0, 0.9);
  border: 2px solid rgba(189, 151, 95, 0.6);
  padding: 4rem 5rem; 
  border-radius: 15px;
  color: #ffffff;
  box-shadow: 0 10px 25px rgba(189, 151, 95, 0.1);
}

.diamond-content-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  margin-bottom: 3.5rem;
}

.diamond-subtitle {
  color: #bd985f;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-transform: uppercase;
  letter-spacing: 0.15rem;
  font-weight: 700;
  position: relative;
  padding-bottom: 0.5rem;
}

.diamond-subtitle::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50px;
  height: 3px;
  background-color: #bd985f;
}

.diamond-intro {
  font-size: 2.4rem;
  line-height: 1.6;
  color: #e0e0e0;
  font-weight: 500;
}

.diamond-perks {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.diamond-perk {
  font-size: 2.2rem;
  color: #f0f0f0;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 2.5rem;
  font-weight: 700;
  transition: color 0.3s ease;
}

.diamond-perk:hover {
  color: #bd985f;
}

.diamond-line {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateX(-50%);
  width: 1.8rem;
  height: 3px;
  background-color: #bd985f;
  transition: width 0.3s ease;
}

.diamond-perk:hover .diamond-line {
  width: 2.5rem;
}

.diamond-cta {
  font-size: 2.4rem;
  color: #bd985f;
  text-align: center;
  font-weight: 700;
  letter-spacing: 0.1rem;
  margin-top: 1rem;
}

@media (max-width: 64em) {
  .diamond-content-grid {
    grid-template-columns: 1fr;
  }

  .diamond-box h2 {
    font-size: 5rem;
  }
}

@media (max-width: 48em) {
  .diamond-box h2 {
    font-size: 4rem;
  }

  .diamond-card {
    padding: 3rem 2.5rem;
  }

  .diamond-subtitle {
    font-size: 2.2rem;
    text-align: center;
  }

  .diamond-subtitle::after {
    display: none;
  }

  .diamond-intro,
  .diamond-perk,
  .diamond-cta {
    text-align: center;
  }

  .diamond-perks {
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .diamond-perk {
    padding-left: 0;
  }

  .diamond-line {
    display: none;
  }

  .diamond-perk::before {
    content: "◆";
    transform: translateY(5%);
    color: #bd985f;
    font-size: 1.8rem;
    margin-right: 5px; 
    line-height: 1;
  }
}

@media (max-width: 30em) {
  .diamond-box h2 {
    font-size: 3rem;
  }

  .diamond-intro {
    font-size: 2rem;
  }

  .diamond-perk {
    font-size: 1.8rem;
  }

  .diamond-cta {
    font-size: 2rem;
  }
}