.footer {
  background-color: #777;
  background-color: #e5e1e6;

  padding: 3.2rem 0rem;

  border-top: 1px solid #bd985f;
  border-bottom: 10px solid #bd985f;
}

.grid--footer {
  max-width: 120rem;
  margin: 0 auto;
  padding: 4.8rem 2.4rem;

  display: grid;
  grid-template-columns: 3fr 3fr 2fr 2fr 2fr;
  gap: 2.4rem;
}

.logo-col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2.4rem;

  margin-right: 2.4rem;
}

.footer-logo {
  margin-bottom: 4.8rem;
  font-weight: 600;
  text-decoration: none;

  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}
.footer-logo-img {
  width: 100%;
}
.footer-logo--text h1 {
  font-size: 2.4rem;
  color: #1f487e;
  font-weight: 700;
  margin-bottom: 0.4rem;
}

.footer-logo--text div {
  font-size: 1.6rem;
  color: #333;
  font-weight: 400;
}

.address-col,
.nav-col {
  padding: 2.4rem 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.social-links {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2.4rem;
}

.copyright {
  font-size: 1.6rem;
  line-height: 1.6;
  margin-top: auto;
  color: #333;
  text-align: center;
}

.footer-nav {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}

.footer-heading {
  font-size: 2.2rem;
  font-weight: 900;
  margin-bottom: 2.4rem;
  color: #333;
  padding: 0.2rem 0.4rem;
}

.social-icon {
  height: 3.2rem;
  width: 3.2rem;
}

.footer-link:link,
.footer-link:visited,
.footer-nav p,
/* .social-icon, */
.footer-prompt-btn {
  text-decoration: none;
  font-size: 1.6rem;
  font-weight: 700;
  transition: all 0.3s;
  color: #333;
  cursor: pointer;

  padding: 0.2rem 0.4rem;
  border-radius: 5px;

  display: inline;
}

.footer-link:hover,
.footer-link:active,
/* .social-icon:hover, */
.footer-prompt-btn:hover {
  color: #bd985f;
  font-weight: 700;
}

/*****************************/
/* BELOW 944px (Tablets) */
/*****************************/
@media (max-width: 59em) {
  .grid--footer {
    grid-template-columns: 3fr 3fr 2fr 2fr 2fr;
    gap: 2.4rem;

    padding: 4.8rem 4.8rem;
  }
}

/*****************************/
/* BELOW 704px (Smaller Tablets) */
/*****************************/

@media (max-width: 44em) {
  .grid--footer {
    grid-template-columns: 2fr 2fr 2fr 2fr 2fr 2fr;
    column-gap: 0rem;
    row-gap: 4.8rem;
  }

  .nav-col {
    grid-row: 1;
    grid-column: span 2;
  }

  .logo-col {
    margin-right: 2.4rem;
  }

  .logo-col,
  .address-col {
    grid-column: span 3;
  }

  .logo-col,
  .address-col,
  .nav-col {
    padding: 0 2.4rem;
  }
}

/*****************************/
/* BELOW 544px (Phones) */
/*****************************/

@media (max-width: 34em) {
  .grid--footer {
    padding: 4.8rem 1.2rem;
  }

  .logo-col,
  .address-col,
  .nav-col {
    /* text-align: center; */
    padding: 0 0 0 1.2rem;
  }
}
