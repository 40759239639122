.gallery {
  background-color: inherit;
}

.gallery h2 {
  text-align: center;

  color: #333;
}

.gallery-div-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0.8rem;
}

.gallery-div-img {
  /* width: 100%; */
  overflow: hidden;
  transition: all 0.5s;
}

.gallery-img {
  width: 100%;
  height: 100%;
  transition: all 0.5s;
}

.gallery-img:hover {
  transform: scale(1.08);
}

/* GENERAL QUERIES */

/*****************************/
/* BELOW 1344px (Smaller desktops) */
/*****************************/

@media (max-width: 84em) {
  /* NONE */
}

/*****************************/
/* BELOW 704px (Smaller Tablets) */
/*****************************/

@media (max-width: 44em) {
  .gallery-div-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
  }
}

/*****************************/
/* BELOW 544px (Phones) */
/*****************************/

@media (max-width: 34em) {
  .gallery {
    padding: 9.6rem 0.8rem 4.8rem 0.8rem;
  }
  .gallery-div-grid {
    gap: 0.6rem;
  }
}
