.prompt {
  background-color: rgba(119, 119, 119, 0.75);

  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 99999;
}

.prompt-box {
  background-color: #fff;
  border-radius: 5px;
  padding: 4.8rem;

  position: absolute;
  top: 50%;
  left: 50%;
  height: 70vh;
  width: 50vw;
  transform: translate(-50%, -50%);
  z-index: 99999;

  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}

.prompt-hidden {
  display: none;
}

.prompt-box-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0rem 0rem 3.6rem;
}
.prompt-title {
  font-size: 3.2rem;
}

.prompt-content {
  overflow-y: scroll;
  height: 100%;

  font-size: 2rem;
  font-weight: 500;

  padding: 0rem 2.4rem 2.4rem 2.4rem;

  border: 1px solid #ddd;
  border-radius: 5px;
}

.prompt ol,
.prompt ul {
  /* list-style: none; */
  padding: 0rem 0rem 0 2.4rem;
}

.prompt-notes-h3 {
  margin-top: 2.4rem;
}

.prompt-button {
  display: flex;
  align-items: center;
  justify-content: center;
  /* align-self: center; */

  padding: 0.6rem;
  letter-spacing: 1.2px;
  font-size: 2.4rem;
  font-weight: 700;
  border-radius: 5px;
  border: 1px solid #bd985f;
  background-color: #fff;
  color: #bd985f !important;
  cursor: pointer;

  transition: all 0.3s;
}

.prompt-button:hover,
.prompt-button:active {
  background-color: #000;
  color: #ccc;
}

.prompt-button-icon {
  height: 2rem;
  width: 2rem;
  color: #bd985f !important;
}

/* FORM */
.prompt-form-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* background-color: #000; */
}
.prompt-form {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  text-align: center;
  padding: 0 2.4rem 0 3.6rem;
  color: #333;
}
.prompt-form-label {
  font-size: 2rem;
}
.prompt-form-input {
  margin-right: 0.6rem;
}
.prompt-form-button {
  padding: 0.6rem 1.2rem;
  cursor: pointer;
  border: 1px solid #333;
  border: none;
  border-radius: 5px;
  background-color: #ccc;
}

/* GENERAL QUERIES */

/*****************************/
/* BELOW 1344px (Smaller desktops) */
/*****************************/

@media (max-width: 84em) {
  .prompt-box {
    background-color: #fff;
    border-radius: 5px;
    padding: 4.8rem;

    position: absolute;
    top: 50%;
    left: 50%;
    height: 70vh;
    width: 60vw;
    transform: translate(-50%, -50%);
    z-index: 99999;

    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }
}

/*****************************/
/* BELOW 1200px (Landscape Tablets) */
/*****************************/

@media (max-width: 75em) {
  .prompt-box {
    background-color: #fff;
    border-radius: 5px;
    padding: 4.8rem;

    position: absolute;
    top: 50%;
    left: 50%;
    height: 70vh;
    width: 70vw;
    transform: translate(-50%, -50%);
    z-index: 99999;

    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }
}

/*****************************/
/* BELOW 944px (Tablets) */
/*****************************/

@media (max-width: 59em) {
  .prompt-box {
    background-color: #fff;
    border-radius: 5px;
    padding: 4.8rem;

    position: absolute;
    top: 50%;
    left: 50%;
    height: 80vh;
    width: 80vw;
    transform: translate(-50%, -50%);
    z-index: 99999;

    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }
}

/*****************************/
/* BELOW 704px (Smaller Tablets) */
/*****************************/

@media (max-width: 44em) {
}

/*****************************/
/* BELOW 544px (Phones) */
/*****************************/

@media (max-width: 34em) {
  .prompt-box {
    background-color: #fff;
    border-radius: 5px;
    padding: 2.4rem;

    position: absolute;
    top: 50%;
    left: 50%;
    height: 80vh;
    width: 90vw;
    transform: translate(-50%, -50%);
    z-index: 99999;

    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }
}
