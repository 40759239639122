.floating-book-button {
  position: fixed;
  z-index: 999;
  border: 3px solid #bd985f;
  color: #bd985f !important;
  background-color: #222;
  padding: 0.1rem 1.5rem;
  font-size: 2.8rem;
  font-weight: 700;
  border-radius: 14px;
  box-shadow: 0 12px 35px rgba(0, 0, 0, 0.45);
  cursor: pointer;
  text-decoration: none;
  display: inline-block;

  /* Suavidade */
  transition:
    top 1s ease,
    left 1s ease,
    transform 1s ease,
    opacity 1s ease;
}

/* Centro da Hero Section */
.floating-book-button.center-hero {
  top: 88%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
}

/* Canto inferior direito — com base em bottom/right */
.floating-book-button.fade-in {
  bottom: 40px; /* distância do fundo */
  right: 40px; /* distância da direita */
  transform: translate(0, 0); /* Remove o translate para fixar */
  transition: 0.5s ease-out;
  opacity: 1;
}

.floating-book-button:hover {
  transform: translate(0, -3%);
  background-color: rgba(189, 151, 95, 0.5) !important;
  color: #fff !important;
}

.floating-book-text {
  transform: translateY(-6%);
}

/*****************************/
/* BELOW 544px (Phones) */
/*****************************/
@media (max-width: 34em) {
  .floating-book-button {
    padding: 0.2rem 1.5rem;
    border-radius: 8px;
    font-size: 2.6rem;
  }

  .floating-book-button.fade-in {
    bottom: 20px;
    right: 20px;
  }
}

/* Desaparecer suavemente */
.floating-book-button.fade-out {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s ease-in-out;
}

/* BELOW 1344px (Smaller desktops) */
@media (max-width: 184em) {
  .floating-book-button {
    display: none;
  }
}

/* BELOW 1200px (Landscape Tablets) */
@media (max-width: 75em) {
  .floating-book-button {
    display: none;
  }
}

/* BELOW 944px (Tablets) */
@media (max-width: 704px) {
  .floating-book-button {
    display: block;
  }
}

/* BELOW 704px (Smaller Tablets) */
@media (max-width: 44em) {
  .floating-book-button {
    display: block;
  }
}

/* BELOW 544px (Phones) */
@media (max-width: 34em) {
  .floating-book-button {
    display: block;
  }
}