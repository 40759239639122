.membership-content {
  /* none */
  background-color: inherit;
}

.membership-content h2 {
  color: #333;
  padding: 0 2.4rem;
  /* text-align: center; */
}

.member-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 9.6rem;
}

/* Host the intro and advantages */
.member-grid-div {
  padding: 0 2.4rem;
  display: grid;
  grid-template-columns: 4fr 3fr;
  gap: 4.8rem;
  align-items: center;
}

.member-intro-div {
  padding: 0rem 0rem;
  border-radius: 5px;
  height: 100%;
}

.member-intro-div-icon {
  height: 6.2rem;
  width: 6.2rem;
  color: #bd985f;
  margin-bottom: 1.8rem;
}
.member-intro-div-h3 {
  margin-bottom: 1.8rem;
  color: #333;
  font-size: 3.6rem;
  letter-spacing: 1.4px;
  font-weight: 700;
  text-transform: capitalize;
}
.member-intro-div-p {
  font-size: 2.4rem;
  color: #333;
  font-weight: 500;
  margin-bottom: 1.2rem;
}
.member-grid-advantages {
  padding: 3.6rem;
  border-radius: 5px;
  height: 100%;
  border: 1px solid #ddd;
  box-shadow: 0 0.6rem 1.2rem rgba(0, 0, 0, 0.1);
  background-color: #eee;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.member-grid-advantages-h3 {
  margin: 0 0 2.4rem 0;
  color: #000;
  font-size: 3.6rem;
  letter-spacing: 1.4px;
  font-weight: 600;
  text-transform: capitalize;
  text-align: center;
}

.member-grid-div ul {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  list-style: none;
}

.member-grid-div li {
  display: flex;
  gap: 0.6rem;
  font-size: 2.2rem;
  font-weight: 500;
  color: #333;
}
.member-grid-div-icon {
  height: 1.4rem;
  width: 1.4rem;
  color: #bd985f;

  align-self: flex-start;
}

.member-plans-h3-mob {
  text-align: center;
  font-size: 5rem;
  color: #333333;
}

.member-plans-box {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 1.2rem;
  row-gap: 2.4rem;

  margin: 0 1.2rem;
}

.member-plans-box-div:last-child {
  background-color: #e5e1e6;
  /* background-color: #eee; */
}


.member-plans-box ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 0.6rem;
  padding: 3.2rem 3.2rem 3.2rem 3.2rem;

  /* filter: blur(2px); */
}

.member-plans-box li {
  display: flex;
  gap: 0.6rem;
  /* align-items: center; */
  font-size: 1.8rem;
  font-weight: 700;
  letter-spacing: 1.2px;
  color: #777;
  /* color: #aaa; */
}




.member-details {
  margin-top: 3.2rem;
  font-size: 1.6rem;
  font-weight: 600;
  padding: 0 2.4rem;
}

/* GENERAL QUERIES */

/*****************************/
/* BELOW 1344px (Smaller desktops) */
/*****************************/

@media (max-width: 84em) {
  /* NONE */
}

/*****************************/
/* BELOW 1200px (Landscape Tablets) */
/*****************************/

@media (max-width: 75em) {
  .member-grid-div {
    padding: 0 2.4rem;
    display: grid;
    grid-template-columns: 2fr 2fr;
    gap: 4.8rem;
    align-items: center;
  }

  .member-plans-box {
    gap: 0.8rem;
  }
}

/*****************************/
/* BELOW 944px (Tablets) */
/*****************************/

@media (max-width: 59em) {
  .member-grid-div {
    padding: 0 2.4rem;
    grid-template-columns: 2fr 2fr;
    gap: 2.4rem;
  }

  .member-grid-advantages {
    padding: 2.4rem;
  }

  .member-plans-box {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.6rem;

    margin: 0 4.8rem;
  }

  .member-details {
    padding: 0 4.8rem;
  }
}

/*****************************/
/* BELOW 704px (Smaller Tablets) */
/*****************************/

@media (max-width: 44em) {
  .membership-content h2 {
    text-align: center;
  }

  .member-grid-div {
    padding: 0 6.2rem;

    display: grid;
    grid-template-columns: 1fr;
    gap: 4.8rem;
  }

  .member-grid-advantages {
    padding: 4.8rem;
  }

  .member-plans-h3-mob {
    display: block;
    text-align: center;
    padding-top: 2.4rem;
    color: #333;
    font-size: 3.6rem;
    letter-spacing: 1.4px;
    font-weight: 700;
    text-transform: capitalize;
  }

  .member-plans-box {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.2rem;
  }

  .member-details {
    padding: 0 6.2rem;
  }
}

/*****************************/
/* BELOW 544px (Phones) */
/*****************************/

@media (max-width: 34em) {
  .member-grid-div {
    padding: 0 1.2rem;
    grid-template-columns: 1fr;
    gap: 4.8rem;
    /* margin: 0 1.2rem; */
  }
  .member-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 4.8rem;
  }
  .member-grid-advantages {
    padding: 3.6rem 2.4rem;
    margin: 0;
  }
  .member-grid h3 {
    margin: 0 0 2.8rem 0;
  }
  .member-plans-box {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.6rem;

    margin: 0 1.2rem;
  }

  .member-details {
    padding: 0 2.4rem;
  }
}