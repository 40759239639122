.cta {
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  background-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.5),
      rgba(68, 68, 68, 0.5)
    ),
    url(../../public/cta-background.webp);
  background-size: cover;
  background-position: top;
}

.cta-box {
  background-color: inherit;
}

.cta-box h2 {
  color: #bd985f;
  text-align: center;
}

.cta-box-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1.2rem;

  align-items: center;
  justify-content: center;
  text-align: center;
}

.grid-test {
  display: flex;
  align-items: center;
}

.cta-card {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  align-items: center;
  justify-content: flex-start;
  padding: 3.6rem 1.2rem;
  text-decoration: none;
  border-radius: 5px;
  overflow: hidden;
  height: 28rem;
  width: 100%;

  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.75),
    rgba(68, 68, 68, 0.75)
  );
  background-size: cover;
  background-position: top;
  border-left: 1px solid rgba(189, 151, 95, 0.35);
  border-right: 1px solid rgba(189, 151, 95, 0.35);
  border-top: 5px solid rgba(189, 151, 95);
  border-bottom: 5px solid rgba(189, 151, 95);

  transition: all 0.3s;
}

.cta-card:hover {
  transform: scale(1.03);
}

.cta-card-icon {
  width: 3.6rem;
  height: 3.6rem;
  color: #bd985f;
  text-align: center;
  display: block;
}

.cta-card-title {
  display: block;
  text-align: center;
  font-weight: 700;
  font-size: 3rem;
  color: #fff;
  text-decoration: none;
  transition: all 0.3s;
}

.cta-card-text,
.cta-card-note,
.cta-card-schedule {
  margin: 0rem 2.4rem 0 2.4rem;
  font-size: 2rem;
  display: block;
  line-height: 1;
  color: #fff;
  text-decoration: none;
  transition: all 0.3s;
  opacity: 1;

  text-align: center;
}

.cta-card-note {
  margin: 3.6rem 2.4rem 2.4rem 2.4rem;
}
.cta-card-schedule {
  line-height: 1.3;
}

.cta-card-title:hover,
.cta-card-text:hover {
  color: #bd985f;
  transform: scale(1.1);
}

/* GENERAL QUERIES */

/*****************************/
/* BELOW 1344px (Smaller desktops) */
/*****************************/

@media (max-width: 84em) {
  /* NONE */
}

/*****************************/
/* BELOW 1200px (Landscape Tablets) */
/*****************************/

@media (max-width: 75em) {
  .cta-box-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1.2rem;

    padding: 0 1.2rem;
  }
}

/*****************************/
/* BELOW 944px (Tablets) */
/*****************************/

@media (max-width: 59em) {
  .cta-box-grid {
    gap: 0.6rem;
    padding: 0 1.2rem;
  }
}

/*****************************/
/* BELOW 704px (Smaller Tablets) */
/*****************************/

@media (max-width: 44em) {
  .cta-box-grid {
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
    padding: 0 4.8rem;
  }
}

/*****************************/
/* BELOW 544px (Phones) */
/*****************************/

@media (max-width: 34em) {
  .cta-card:hover {
    transform: scale(1);
  }
  .cta-box-grid {
    padding: 0 0rem;
  }
}
