
.member-plans-box-div {
  display: flex;
  flex-direction: column;
  background-color: #fff;

  border: 1px solid #eee;
  border-radius: 5px;
  overflow: hidden;
  transition: all 0.3s;

  /* background-color: #bd985f; */
}
.member-plans-box-div-black {
  display: flex;
  flex-direction: column;

  border: 1px solid #eee;
  border-radius: 5px;
  overflow: hidden;
  transition: all 0.3s;

  background-color: #000;
}

.member-plans-box-div:hover,
.member-plans-box-div-black:hover {
  transform: translateY(-10px);
  /* background-color: #bbb; */
  box-shadow: 0 1.2rem 3.2rem rgba(0, 0, 0, 0.1);
}

.member-plan-title {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.95),
      rgba(0, 0, 0, 0.6)
    ),
    url(../../public/Pattern/pattern-2.webp);
  background-size: cover;
  background-position: top;

  display: flex;
  flex-direction: column;
  padding: 4.8rem 0 2.4rem 0;
  overflow: hidden;
}

.member-plan-title span {
  display: block;
  text-align: center;
  font-size: 1.8rem;
  font-weight: 600;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  color: #bd985f;
}
.member-plan-title h4 {
  font-size: 3.2rem;
  font-weight: 900;
  text-align: center;
  padding: 0;
  line-height: 1;
  letter-spacing: 2px !important;
  text-transform: uppercase;
  color: #bd985f;
}

.member-plans-box-price {
  display: block;
  font-size: 4.2rem;
  font-weight: 900;
  /* color: #222; */
  color: #fff;
  text-align: center;

  padding: 0rem;
}


.member-plan-icon {
  color: #bd985f;
  margin-top: 0.4rem;
  align-self: flex-start;
  height: 2rem;
  width: 2rem;
}


.plan-focus {
  color: #222;
  /* color: #aaa; */
}
.plan-focus-black {
  /* color: #222; */
  color: #aaa;
}


.subscribe-div {
  display: flex;
  text-align: center;
  justify-content: center;
  border: none;
  background-color: inherit;
  width: 50%;
  margin: 0 auto 3.6rem auto;
}

/* .subscribe-div:hover {
  transform: scale(1.1);
} */


.subscribe-button {
  display: flex;
  text-align: center;
  justify-content: center;
  width: 100%;
  padding: 1rem 1rem;
  border-radius: 5px;
  background-color: #333;
  border: none;
  text-decoration: none;
  text-transform: uppercase;
  align-self: center;
  font-family: "Darker Grotesque", sans-serif;
  letter-spacing: 1.4px;
  font-size: 2rem;
  font-weight: 700;
  color: #bd985f !important;
  cursor: pointer;
  transition: all 0.3s;
}
.subscribe-button:hover,
.subscribe-button:active {
  background-color: #000;
  /* transform: scale(1.1); */
}