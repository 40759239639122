.team {
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.95),
      rgba(68, 68, 68, 0.5)
    ),
    url(../../public/cta-background.webp);
  background-size: cover;
  background-position: top;
}

.team-box {
  background-color: inherit;
}

.team-box h2 {
  text-align: center;
  color: #bd985f;
}

.team-box-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1.2rem;

  align-items: center;
  justify-content: center;
  text-align: center;

  position: relative;

  transition: all 0.5s ease;
}

.team-card {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  align-items: center;
  justify-content: flex-end;
  padding: 3.6rem 1.2rem 2.4rem 1.2rem;
  text-decoration: none;
  border-radius: 5px;
  overflow: hidden;
  height: 35rem;
  width: 25rem;
  border-left: 1px solid rgba(189, 151, 95, 0.35);
  border-right: 1px solid rgba(189, 151, 95, 0.35);
  border-top: 5px solid rgba(189, 151, 95);
  border-bottom: 5px solid rgba(189, 151, 95);

  /* transition: all 0.5s; */
}

.team-card-content {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
}

.team-card-title {
  display: block;
  text-align: center;
  font-size: 3.2rem;
  font-weight: 700;
  color: #fff;
  text-decoration: none;
  transition: all 0.3s;

  border-bottom: 1px solid rgba(189, 151, 95, 0.75);
}

.team-card-text {
  margin: 0.6rem 2.4rem 0 2.4rem;
  font-size: 2.2rem;
  font-weight: 600;
  line-height: 1;
  color: #fff;
  text-decoration: none;
  transition: all 0.3s;
  opacity: 1;

  text-align: center;
  /* width: 100%; */
}
.team-card-text:last-child {
  font-size: 1.8rem;
  font-weight: 500;
}
/* .team-card-title:hover,
.team-card-text:hover {
  color: #bd985f;
  transform: scale(1.1);
} */

.team-card:hover {
  transform: scale(1.03);

  .team-card-title {
    color: #bd985f;
    transform: scale(1.1);
  }
}

.team-button {
  background-color: inherit;
  border: none;
  /* border-radius: 55px; */
  /* padding: 1.2rem; */
  cursor: pointer;
  transition: all 0.3s;
  color: rgba(189, 151, 95, 0.75);
}
.team-button:hover {
  transform: scale(1.2);
  color: rgba(189, 151, 95, 1);
}
.team-button-icon {
  height: 4.8rem;
  width: 4.8rem;
}

.team-button-two {
  background-color: #333;
  border: 1px solid #bd985f;
  border-radius: 155px;
  padding: 1.2rem;
  cursor: pointer;
  transition: all 0.3s;
}
.team-button-two:hover {
  transform: scale(1.2);
  background-color: #000;
}
.team-button-icon-two {
  height: 2.4rem;
  width: 2.4rem;
  color: #bd985f;
}

.side-btn-left {
  position: absolute;
  top: 50%;
  transform: translateX(-60%);
}
.side-btn-right {
  position: absolute;
  top: 50%;

  transform: translateX(60%);
  right: 0;
}
/* GENERAL QUERIES */

/*****************************/
/* BELOW 1344px (Smaller desktops) */
/*****************************/

@media (max-width: 84em) {
  /* NONE */
}

/*****************************/
/* BELOW 1200px (Landscape Tablets) */
/*****************************/

@media (max-width: 75em) {
  .team-box-grid {
    gap: 1.2rem;
  }

  .team-card {
    padding: 3.6rem 0.8rem 2.4rem 0.8rem;

    width: 23rem;
  }
  .team-card-text {
    font-size: 2rem;
  }
}

/*****************************/
/* BELOW 944px (Tablets) */
/*****************************/

@media (max-width: 59em) {
  .team-box-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1.2rem;
  }

  .team-card {
    padding: 3.6rem 0.8rem 2.4rem 0.8rem;

    width: 25rem;
  }
}

/*****************************/
/* BELOW 704px (Smaller Tablets) */
/*****************************/

@media (max-width: 44em) {
  .team-box-grid {
    grid-template-columns: 1fr 1fr;
  }
}

/*****************************/
/* BELOW 544px (Phones) */
/*****************************/

@media (max-width: 34em) {
  /* .team-box {
    padding: 4.8rem 0;
  } */
  /* .team-box h2 {
    font-size: 3.6rem;
    margin-bottom: 4.8rem;
  } */
  .team-box-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.2rem;

    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .team-card {
    padding: 3.6rem 0.8rem 2.4rem 0.8rem;

    height: 45rem;
    width: 35rem;
  }
}
