.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  backdrop-filter: blur(4px);
}

.zoomed-image {
  max-width: 90vw;
  max-height: 90vh;
  border-radius: 12px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  object-fit: contain;
  animation: zoomIn 0.3s ease-in-out forwards; 
}

.chevron {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 3rem;
  color: white;
  cursor: pointer;
  z-index: 10000;
}

.chevron-left {
  left: 2rem;
}

.chevron-right {
  right: 2rem;
}

.chevron.disabled {
  color: gray;
  pointer-events: none;
}

/* Animação só na imagem */
@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1.35);
  }
}

/* Efeito de foco ao navegar por teclado */
.gallery-div-img:focus {
  outline: 2px solid #00f;
  outline-offset: 2px;
}

/*****************************/
/* BELOW 704px (Smaller Tablets) */
/*****************************/

@media (max-width: 64em) {
  @keyframes zoomIn {
    from {
      opacity: 0;
      transform: scale(0.95);
    }
    to {
      opacity: 1;
      transform: scale(1.05);
    }
  }
}

/*****************************/
/* BELOW 544px (Phones) */
/*****************************/

@media (max-width: 34em) {
  @keyframes zoomIn {
    from {
      opacity: 0;
      transform: scale(0.95);
    }
    to {
      opacity: 1;
      transform: scale(1.0);
    }
  }
}